
<template>
  <div class="text-center mt-5">
      <h1>Accounts</h1>
      <router-link to="/accounts/login"> Login</router-link> /
      <router-link to="/accounts/login"> Signup</router-link>
  </div>
</template>

<script>
export default {

}
</script>